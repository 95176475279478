import React from "react"
import { Link } from "gatsby"


const Pagination = (props) => (

		<div className="w100 left pt0 pb0 mt25 none col-sm-block">
			<ul className="wi left mb0 fw5 fs14">
				<li className="wi left mr10">
					<Link className="w100 left colored3 center-h op7 soft op-hover" to={props.goto}>
						<i className="icon icon-left2"></i> <span className="left mr7 lh36">{props.parent}</span>
					</Link>
				</li>
			</ul>

		</div>
	)
export default Pagination