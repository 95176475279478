import React from "react"
import techStyles from "./technology.module.css"

const Technology = (props) => {

    const _class = props.active ? techStyles.active : techStyles.passive;

    return (
        <span className={`${techStyles.item} ${_class}`}>{props.children}</span>
    )

}

export default Technology