import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Link } from "gatsby"
import SolutionList from "../components/solutions/solution_list"
import Technology from "../components/solutions/techology_item"

import Pagination from "../components/pagination/pagination"


const shortcodes = { Link, Technology }

export default function Template({
    data, // this prop will be injected by the GraphQL query below.
}) {
    const { mdx } = data // data.markdownRemark holds your post data
    const { frontmatter, body } = mdx
    return (
        <Layout>
            <SEO title={frontmatter.title} />
            <div id="solutions" className="inlinePage footerFixed">
                <div className="container-fluid w80">
                    <div className="row">
                    <Pagination parent={`${frontmatter.category}`} goto={`/${frontmatter.category}`}  here={frontmatter.title} />
                        <div className="w20 left pt45 mt25 col-sm-none pr45">
                            <SolutionList />
                        </div>
                        <div className="w80 col-sm-w100 left bl1 bc1 col-sm-bl0 col-sm-pl0 pt25 mt25 pl45">

                            <div className="blog-post-container">
                                <div className="blog-post">
                                    <h1>{frontmatter.title}</h1>
                                </div>

                                <MDXProvider components={shortcodes}>
                                    <MDXRenderer>{body}</MDXRenderer>
                                </MDXProvider>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export const pageQuery = graphql`
  query($path: String!) {
    mdx(frontmatter: { path: { eq: $path } }) {
      body
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        category
      }
    }
  }
`